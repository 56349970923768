html, body, #root {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'XfinityBrown', Arial, Helvetica, sans-serif;
}

body {
    margin: 0;
    font-family: 'XfinityBrown', Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.filmstrip__videos {
    overflow: hidden !important;
}

@font-face {
    font-family: 'XfinityBrown';
    src: local('XfinityBrown'), url(./assets/fonts/XfinityBrown-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'XfinityBrownBold';
    src: local('XfinityBrownBold'), url(./assets/fonts/XfinityBrown-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'XfinityBrownLight';
    src: local('XfinityBrownLight'), url(./assets/fonts/XfinityBrown-Light.otf) format('opentype');
}

.not-supported {
    display: none;
}

@media (hover: none) and  (max-width: 748px) and (orientation: landscape) {
    #hide-on-mobile-landscape {
        visibility: hidden;
    }

    .not-supported {
        display: block;
        background: black;
        width: 100%;
    }
}


.color-change {
    animation: 20000ms ease-in-out infinite color-changer;
    /* background: radial-gradient(circle, #EB8B57 0%, #E64F00 100%);*/
    background-color: #6138F5;
    background: -moz-radial-gradient(rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  
  }
  
  @keyframes color-changer {
    0% {
      background-color: #6138F5;
      /* background: radial-gradient(circle, #9C83F6 0%, #6138F5 100%); */
    }
    
    15% {
      background-color: #1F69FF;
      /* background: radial-gradient(circle, #699BFF 0%, #1F69FF 100%); */
    }
  
    30% {
      background-color: #008558;
      /* background: radial-gradient(circle, #1BAF7D 0%, #008558 100%); */
    }
  
    45% {
      background-color: #FFAA00;
      /* background: radial-gradient(circle, #FFD175 0%, #FFAA00 100%); */
    }
  
    60% {
      background-color: #E64F00;
      /* background: radial-gradient(circle, #EB8B57 0%, #E64F00 100%); */
    }
  
    75% {
      background-color: #E6004D;
      /* background: radial-gradient(circle, #E65C8A 0%, #E6004D 100%); */
    }
    
    100% {
      background-color: #6138F5;
      /* background: radial-gradient(circle, #9C83F6 0%, #6138F5 100%); */
    }
  }